<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>活动管理</el-breadcrumb-item>
                    <el-breadcrumb-item>拼手气红包</el-breadcrumb-item>
                    <el-breadcrumb-item>配置列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="起止日期">
                            <el-date-picker
                                    v-model="date"
                                    @change="changeDate"
                                    @input="immediUpdate"
                                    type="daterange"
                                    range-separator="~"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="截止日期"
                                    :clearable="true"
                                    unlink-panels
                            >
                                <i class="el-icon-date"></i>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="设置日期">
                            <el-date-picker
                                    v-model="where.receiveDt"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()">条件筛选</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="add"
                                    icon="el-icon-plus">新增
                            </el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    disabled
                                    icon="el-icon-tickets">导出数据
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                >
<!--                    <el-table-column prop="id" label="ID"></el-table-column>-->
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    <el-table-column prop="dayNums" label="昨⽇⽇活量"></el-table-column>
                    <el-table-column prop="uniAdvertiseAmount" label="昨⽇⼴告收入"></el-table-column>
                    <el-table-column prop="dayMaxAdvertiesNums" label="每人领取个数"></el-table-column>
                    <el-table-column prop="minMoney" label="最小红包金额"></el-table-column>
                    <el-table-column prop="maxMoney" label="最大红包金额"></el-table-column>
                    <el-table-column prop="date" label="设置日期"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="80">
                        <template slot-scope="scope">
                            <el-button
                                    type="text"
                                    class="el-icon-edit"
                                    size="medium"
                                    @click="add(scope.row)">修改
                            </el-button>
                            <!--<el-button
                                    type="text"
                                    class="el-icon-edit"
                                    size="medium"
                                    @click="del(scope.row)">删除
                            </el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination
                            @current-change="currentChange"
                            @size-change="handleSizeChange"
                            background
                            :total="totalData"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 50, 100]"
                            layout="sizes, prev, pager, next, total, jumper"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        components: {},
        data() {
            return {
                span: 6,
                span4: 5,
                span12: 12,
                span10: 10,
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogDetailVisible: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                selectId: "",
                where: {
                    pageNum: 0,//分页，页数
                    pageSize: 0,//分页，每页大小
                    startDt: '',    // 开始日期，格式【yyyy-MM-dd】
                    endDt: '',    // 结束日期，格式【yyyy-MM-dd】
                    receiveDt:'',  // 领取红包日期【yyyy-MM-dd
                },
            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.where.pageNum = this.currentPage;
                this.where.pageSize = this.pageSize;
                this.$post(this.$api.activityLuckyPacketsList, this.where).then((res) => {
                    this.totalData = res.data.total;
                    this.list = res.data.records;
                    this.loading = false;
                });
            },
            handleSelectionChange(e) {
                let ids = [];
                e.forEach((v) => {
                    ids.push(v.id);
                });
                this.selectId = ids.join(",");
            },
            currentChange(e) {
                this.currentPage = e;
                this.listData();
            },
            handleSizeChange(e) {
                this.pageSize = e;
                this.listData();
            },
            changeDate(obj) {
                if (obj) {
                    this.where.startDt = obj[0];
                    this.where.endDt = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.where.startDt = '';
                        this.where.endDt = '';
                    }
                });
            },
            exportData() {
                // console.log("exportData");
            },

            add(param) {
                let obj = {};
                if(param){
                    obj = {id:param.id};
                }
                this.$router.push({name: "luckyPacketsConfig",query:obj});
            },
            // eslint-disable-next-line no-unused-vars
            del(item){
                /*this.$confirm("删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    this.$get(this.$api.activityLuckyPacketsDelRedTask+item.id,{}).then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                type: "success",
                                message: "成功",
                            });
                            this.listData();
                        }else{
                            this.$message({
                                type: "error",
                                message: res.msg,
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });*/
            },
        },
        created() {
            this.listData();
        },
    };
</script>
<style lang="scss" scoped>
    .total-header {
        margin-bottom: 0 !important;
        margin-top: 15px;

        .el-col {
            margin-right: 15px;

            :last-child {
                margin-right: 0px;
            }
        }
    }

    .el-row {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .header-form {
        height: 40px;
        padding: 10px 0 10px 0;
    }

    .span4 {
        background: rgb(217, 236, 255);
        font-size: 16px;
        padding: 30px 0;
        text-align: center;
        vertical-align: center;

        span {
            font-size: 18px;
        }
    }

    .endDate {
        color: #c0c4cc;
    }

    .el-select {
        width: 120px;
    }
    .status1 {
        color: #E6A23C;
    }

    .status2 {
        color: #67C23A;
    }

    .status8 {
        color: #F56C6C;
    }

    .status4 {
        color: #909399;
    }
</style>
